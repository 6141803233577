.top-placeholder-ven{
    margin-top: 10rem;
    margin-bottom: 10rem;
}

.content-top-ven-div {
    display: block;
    text-align: center;
    font-size: 0.8rem;
    margin-left: 15rem;
    margin-right: 15rem;
}

.weingut {
    color: #CDAEAC;
}

.content-table {
    margin-top: 3rem;
    display: grid;
    justify-content: center;
    font-size: 0.8rem;
    border-collapse: collapse;
    margin-left: 15rem;
    margin-right: 15rem;
}

.content-table th {
    width: 8rem;
    text-align: left;
}

.content-table td {
    width: 17rem;
    text-align: left;
}

.iframe-map-left {
    position: grid;
    justify-content: center;
    width: 40rem;
    height: 30rem;
    border: 0;
}

.iframe-map-right {
    position: grid;
    justify-content: center;
    width: 40rem;
    height: 30rem;
    border: 0;
}

.content-ven-left{
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    margin-left: 15rem;
    margin-right: 15rem;
}

.content-ven-right{
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    margin-left: 15rem;
    margin-right: 15rem;
}

@media (max-width: 2441px) {
    .content-top-ven-div {
        font-size: 1rem;
    }

    .content-table{
        font-size: 1rem;
    }

    .content-ven-left {
        margin-left: 15rem;
        margin-right: 15rem;
    }

    .content-ven-right {
        margin-left: 15rem;
        margin-right: 15rem;
    }
}

@media (max-width: 1300px) {
    .content-top-ven-div {
        font-size: 0.9rem;
    }

    .content-table{
        font-size: 0.9rem;
    }

    .content-ven-left {
        margin-left: 15rem;
        margin-right: 15rem;
    }

    .content-ven-right {
        margin-left: 15rem;
        margin-right: 15rem;
    }
}

@media (max-width: 1300px) {
    .content-top-ven-div {
        font-size: 0.9rem;
        margin-left: 10rem;
        margin-right: 10rem;
    }

    .content-table{
        font-size: 0.9rem;
        margin-left: 10rem;
        margin-right: 10rem;
    }

    .content-ven-left {
        margin-left: 10rem;
        margin-right: 10rem;
    }

    .content-ven-right {
        margin-left: 10rem;
        margin-right: 10rem;
    }
}

@media (max-width: 900px) {
    .content-top-ven-div {
        font-size: 0.8rem;
        margin-left: 8rem;
        margin-right: 8rem;
    }

    .content-table{
        font-size: 0.8rem;
        margin-left: 8rem;
        margin-right: 8rem;
    }

    .content-ven-left {
        margin-left: 8rem;
        margin-right: 8rem;
    }

    .content-ven-right {
        margin-left: 8rem;
        margin-right: 8rem;
    }
}

@media (max-width: 600px) {
    .content-top-ven-div {
        font-size: 0.7rem;
        margin-left: 5rem;
        margin-right: 5rem;
    }

    .content-table{
        font-size: 0.7rem;
        margin-left: 5rem;
        margin-right: 5rem;
    }

    .content-ven-left {
        margin-left: 5rem;
        margin-right: 5rem;
    }

    .content-ven-right {
        margin-left: 5rem;
        margin-right: 5rem;
    }
}

@media (max-width: 500px) {
    .content-top-ven-div {
        font-size: 0.7rem;
        margin-left: 3rem;
        margin-right: 3rem;
    }

    .content-table{
        font-size: 0.7rem;
        margin-left: 3rem;
        margin-right: 3rem;
    }

    .content-ven-left {
        margin-left: 3rem;
        margin-right: 3rem;
    }

    .content-ven-right {
        margin-left: 3rem;
        margin-right: 3rem;
    }
}

@media (max-width: 450px) {
    .content-top-ven-div {
        font-size: 0.6rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .content-table{
        font-size: 0.6rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .content-ven-left {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .content-ven-right {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

@media (max-width: 300px) {
    .content-top-ven-div {
        font-size: 0.4rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .content-table{
        font-size: 0.4rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .content-ven-left {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .content-ven-right {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}
