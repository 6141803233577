.menu {
    width: 100%;
    background-color: #F7F7F7;
    position: fixed;
    top: 0;
    left: 0;
}

.menu ul {
    list-style-type: none;
    margin: 0 auto;
    padding: 0;
    overflow: hidden;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu li {
    flex-grow: 1;
    /* text-align: center; */
}

.menu li a {
    display: block;
    color: black;
    padding: 14px 16px;
    text-decoration: none;
}

.menu li a:hover {
    color: darkgray;
}

.menu-text {
    font-size: 14px;
}

.logo-style {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
