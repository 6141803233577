.menu {
    width: 100%;
    background-color: #F7F7F7;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    z-index: 1000;
}

.menu-items {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.menu-items.active {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    background-color: #F7F7F7;
    padding: 0;
}

.menu-toggle {
    display: none;
    cursor: pointer;
    margin-left: auto;
}

.hamburger,
.hamburger::before,
.hamburger::after {
    background-color: black;
    height: 2px;
    width: 25px;
    transition: all 0.3s ease-in-out;
    position: relative;
}

.hamburger::before,
.hamburger::after {
    content: "";
    position: absolute;
    left: 0;
}

.hamburger::before { top: -8px; }
.hamburger::after { top: 8px; }

.hamburger.open {
    transform: rotate(45deg);
}

.hamburger.open::before,
.hamburger.open::after {
    top: 0;
    transform: rotate(90deg);
}


.flex-child-element:first-child {
    margin-right: 20px;
}

/* Responsive adjustments */
@media (max-width: 1150px) {
    .menu li {
        font-size: 0.7rem;
    }
}

@media (max-width: 1000px) {
    .menu li {
        font-size: 0.6rem;
    }
}

@media (max-width: 950px) {
    .menu-toggle {
        display: flex;
        margin-right: 17rem;
        margin-top: 3rem;
    }

    .menu-items {
        display: none;
        position: absolute;
        flex-direction: column;
        width: 100%;
        top: 60px;
        left: 0;
        background-color: #F7F7F7;
    }

    .menu-items.active {
        display: flex;
        position: absolute;
        flex-direction: column;
        width: 100%;
        top: 60px;
        left: 0;
        background-color: #F7F7F7;
    }

    .menu li {
        text-align: center;
        width: 100%;
        font-size: 1.2rem;
    }

    .menu ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

}

@media (max-width: 800px) {

    /* burger menu */
    .menu-toggle {
        display: flex;
        margin-right: 13rem;
        margin-top: 3rem;
    }

    .menu-items {
        display: none;
        position: absolute;
        flex-direction: column;
        width: 100%;
        top: 60px;
        left: 0;
        background-color: #F7F7F7;
    }

    .menu-items.active {
        display: flex;
        position: absolute;
        flex-direction: column;
        width: 100%;
        top: 60px;
        left: 0;
        background-color: #F7F7F7;
    }

    .menu li {
        text-align: center;
        width: 100%;
    }

    .menu ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

}



@media (max-width: 650px) {

    /* burger menu */
    .menu-toggle {
        display: flex;
        margin-right: 10rem;
        margin-top: 3rem;
    }

    .menu-items {
        display: none;
        position: absolute;
        flex-direction: column;
        width: 100%;
        top: 60px;
        left: 0;
        background-color: #F7F7F7;
    }

    .menu-items.active {
        display: flex;
        position: absolute;
        flex-direction: column;
        width: 100%;
        top: 60px;
        left: 0;
        background-color: #F7F7F7;
    }

    .menu li {
        text-align: center;
        width: 100%;
        font-size: 1rem;
    }

    .menu ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

}


@media (max-width: 500px) {

    /* burger menu */
    .menu-toggle {
        display: flex;
        margin-right: 7rem;
        margin-top: 3rem;
    }

    .menu-items {
        display: none;
        position: absolute;
        flex-direction: column;
        width: 100%;
        top: 60px;
        left: 0;
        background-color: #F7F7F7;
    }

    .menu-items.active {
        display: flex;
        position: absolute;
        flex-direction: column;
        width: 100%;
        top: 60px;
        left: 0;
        background-color: #F7F7F7;
    }

    .menu li {
        text-align: center;
        width: 100%;
        font-size: 0.8rem;
    }

    .menu ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

}

@media (max-width: 415px) {

    /* burger menu */
    .menu-toggle {
        display: flex;
        margin-right: 4rem;
        margin-top: 3rem;
    }

    .menu-items {
        display: none;
        position: absolute;
        flex-direction: column;
        width: 100%;
        top: 60px;
        left: 0;
        background-color: #F7F7F7;
    }

    .menu-items.active {
        display: flex;
        position: absolute;
        flex-direction: column;
        width: 100%;
        top: 60px;
        left: 0;
        background-color: #F7F7F7;
    }

    .menu li {
        text-align: center;
        font-size: 0.8rem;
        width: 100%;
    }

    .menu ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
}

@media (max-width: 375px) {

    /* burger menu */
    .menu-toggle {
        display: flex;
        margin-right: 4rem;
        margin-top: 3rem;
    }

    .menu-items {
        display: none;
        position: absolute;
        flex-direction: column;
        width: 100%;
        top: 60px;
        left: 0;
        background-color: #F7F7F7;
    }

    .menu-items.active {
        display: flex;
        position: absolute;
        flex-direction: column;
        width: 100%;
        top: 60px;
        left: 0;
        background-color: #F7F7F7;
    }

    .menu li {
        text-align: center;
        font-size: 0.5rem;
        width: 100%;
    }

    .menu ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
}

@media (max-width: 350px) {

    /* burger menu */
    .menu-toggle {
        display: flex;
        margin-right: 4rem;
        margin-top: 3rem;
    }

    .menu-items {
        display: none;
        position: absolute;
        flex-direction: column;
        width: 100%;
        top: 60px;
        left: 0;
        background-color: #F7F7F7;
    }

    .menu-items.active {
        display: flex;
        position: absolute;
        flex-direction: column;
        width: 100%;
        top: 60px;
        left: 0;
        background-color: #F7F7F7;
    }

    .menu li {
        text-align: center;
        font-size: 0.5rem;
        width: 100%;
    }

    .menu ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
}