.content-reg{
    display: block;
    margin-top: 0rem;
    margin-left: 3rem;
    margin-right: 1rem;
    margin-bottom: 2rem;
    text-align: justify;
}

.dance-image-reg{
    display: block;
    width: 95%;
    height: auto;
    border-radius: 30px;
}

.column-def-reg{
    display: flex;
    margin-top: 8rem;
    margin-left: 10rem;
    margin-right: 10rem;
    margin-bottom: 2rem;
}

.column {
    flex: 50%;
}

.reg-placeholder-end{
    margin-bottom: 10rem;
}

@media (max-width: 1629px) {
    .content-reg{
        margin-left: 3rem;
        margin-right: 1rem;
    }

    .dance-image-reg{
        width: 90%;
    }
}

@media (max-width: 1530px) {
    .content-reg{
        margin-left: 2.6rem;
        margin-right: 1rem;
    }

    .dance-image-reg{
        width: 90%;
    }

    .column-def-reg{
        margin-left: 8rem;
        margin-right: 8rem;
    }
}

@media (max-width: 1400px) {
    .content-reg{
        margin-left: 2.6rem;
        margin-right: 1rem;
    }

    .dance-image-reg{
        width: 90%;
    }

    .column-def-reg{
        margin-left: 6rem;
        margin-right: 6rem;
    }
}

@media (max-width: 1300px) {
    .content-reg{
        margin-left: 2.6rem;
        margin-right: 1rem;
        font-size: 0.9rem;
    }

    .dance-image-reg{
        width: 90%;
    }

    .column-def-reg{
        margin-left: 6rem;
        margin-right: 6rem;
    }
}

@media (max-width: 1300px) {
    .content-reg{
        margin-left: 2.2rem;
        margin-right: 0.7rem;
        font-size: 0.9rem;
    }

    .dance-image-reg{
        width: 90%;
    }

    .column-def-reg{
        margin-left: 4rem;
        margin-right: 4rem;
    }
}

@media (max-width: 1000px) {
    .content-reg{
        margin-left: 2.2rem;
        margin-right: 0.7rem;
        font-size: 0.8rem;
    }

    .dance-image-reg{
        width: 90%;
    }

    .column-def-reg{
        margin-left: 2rem;
        margin-right: 2rem;
    }
    .reg-placeholder-end{
        margin-top: 20rem;
    }
}

@media (max-width: 850px) {
    .content-reg{
        margin-left: 1.5rem;
        margin-right: 0.3rem;
        font-size: 0.7rem;
    }

    .dance-image-reg{
        width: 90%;
    }

    .column-def-reg{
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .reg-placeholder-end{
        margin-top: 10rem;
    }
}

@media (max-width: 650px) {
    .content-reg{
        margin-left: 1.5rem;
        margin-right: 0.3rem;
        font-size: 0.6rem;
    }

    .dance-image-reg{
        width: 90%;
    }

    .column-def-reg{
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .reg-placeholder-end{
        margin-top: 20rem;
    }
}

@media (max-width: 550px) {
    .content-reg{
        font-size: 0.5rem;
    }

    .reg-placeholder-end{
        margin-top: 20rem;
    }
}

@media (max-width: 500px) {
    .content-reg{
        margin-left: 1rem;
        margin-right: 0.1rem;
        font-size: 0.4rem;
    }
    .column-def-reg{
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }

    .reg-placeholder-end{
        margin-top: 30rem;
    }

}

@media (max-width: 400px) {
    .content-reg{
        margin-left: 1rem;
        margin-right: 0.1rem;
        font-size: 0.4rem;
    }
    .column-def-reg{
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }

    .reg-placeholder-end{
        margin-top: 30rem;
    }
}
