.content-travel {
    display: block;
    margin-top: 7rem;
    text-align: center;
}

.travel-title {
    font-size: 2rem;
    font-weight: 700;
    margin-top: 8rem;
    margin-bottom: 1.5rem;
}

.info-text {
    display:flex;
}

.info-text p {
    font-size: 1rem;
    text-align: justify;
    max-width: 100%;
    padding: 0 1rem;
}

.row-travel {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 1rem auto;
    max-width: 1200px;
}

.column-travel {
    width: calc(50% - 1rem);
    text-align: center;
    margin: 0 0.5rem;
    box-sizing: border-box;
}

.column-title {
    font-size: 1.5rem;
    font-weight: 700;
}

.dev-border {
    border-right: black 1px solid;
}

.address-def{
    text-align: center;
    font-size: 1rem;
}

.def-hotel-name {
    font-weight: 700;
}

.hotel-image {
    width: 50%;
    height: auto;
}

.placeholder {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

@media (max-width: 4000px) {
    .travel-title {
        font-size: 3rem;
    }
    .info-text p {
        padding: 0 35rem;
        font-size: 1.5rem;
    }

    .column-title {
        font-size: 2rem;
    }

    .address-def {
        font-size: 1.5rem;
    }

    .hotel-image {
        width: 50%;
    }
}

@media (max-width: 2100px) {
    .travel-title {
        font-size: 3rem;
    }
    .info-text p {
        padding: 0 30rem;
        font-size: 1.5rem;
    }

    .column-title {
        font-size: 2rem;
    }

    .address-def {
        font-size: 1.5rem;
    }

    .hotel-image {
        width: 50%;
    }
}

@media (max-width: 1800px) {
    .travel-title {
        font-size: 2.5rem;
    }
    .info-text p {
        padding: 0 25rem;
        font-size: 1.3rem;
    }

    .column-title {
        font-size: 1.8rem;
    }

    .address-def {
        font-size: 1.3rem;
    }

    .hotel-image {
        width: 60%;
    }
}

@media (max-width: 1600px) {
    .travel-title {
        font-size: 2.5rem;
    }
    .info-text p {
        padding: 0 20rem;
        font-size: 1.3rem;
    }

    .column-title {
        font-size: 1.8rem;
    }

    .address-def {
        font-size: 1.3rem;
    }

    .hotel-image {
        width: 60%;
    }
}

@media (max-width: 1400px) {
    .travel-title {
        font-size: 2.5rem;
    }
    .info-text p {
        padding: 0 15rem;
        font-size: 1.3rem;
    }

    .column-title {
        font-size: 1.8rem;
    }

    .address-def {
        font-size: 1.3rem;
    }

    .hotel-image {
        width: 50%;
    }
}

@media (max-width: 1200px) {
    .travel-title {
        font-size: 2rem;
    }
    .info-text p {
        padding: 0 15rem;
        font-size: 1rem;
    }

    .column-title {
        font-size: 1.3rem;
    }

    .address-def {
        font-size: 1rem;
    }

    .hotel-image {
        width: 50%;
    }
}

@media (max-width: 1000px) {
    .travel-title {
        font-size: 1.8rem;
    }
    .info-text p {
        padding: 0 10rem;
        font-size: 0.8rem;
    }

    .column-title {
        font-size: 1rem;
    }

    .address-def {
        font-size: 0.8rem;
    }

    .hotel-image {
        width: 50%;
    }
}

@media (max-width: 800px) {
    .travel-title {
        font-size: 1.5rem;
    }
    .info-text p {
        padding: 0 10rem;
        font-size: 0.7rem;
    }

    .column-title {
        font-size: 1rem;
    }

    .address-def {
        font-size: 0.7rem;
    }

    .hotel-image {
        width: 50%;
    }

    .placeholder {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
}

@media (max-width: 650px) {
    .travel-title {
        font-size: 1.5rem;
    }
    .info-text p {
        padding: 0 5rem;
        font-size: 0.7rem;
    }

    .column-title {
        font-size: 1rem;
    }

    .address-def {
        font-size: 0.7rem;
    }

    .hotel-image {
        width: 70%;
    }
}

@media (max-width: 550px) {
    .travel-title {
        font-size: 1.5rem;
    }
    .info-text p {
        padding: 0 3rem;
        font-size: 0.5rem;
    }

    .column-title {
        font-size: 1rem;
    }

    .address-def {
        font-size: 0.5rem;
    }

    .hotel-image {
        width: 80%;
    }
}