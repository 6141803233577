.content-best{
    display: block;
    margin-top: 7rem;
}

.title-best{
    font-size: 2.5rem;
    font-weight: 500;
    text-align: center;
}

.best-column {
    float: left;
    width: 21%;
    height: 10rem;
    text-align: justify;
    margin-left: 2rem;
    margin-right: 2rem;
}

.best-texts {
    font-size: 1rem;
}

.best-mate {
    width: 100%;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 3rem;
}

.best-row {
    margin-top: 1rem;
    margin-bottom: 3rem;
    align-items: center;
}

.best-row:after {
    content: "";
    display: table;
    clear: both;
}

.best-mate-title {
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: 1rem;
    text-align: center;
}

.best-mate-text{
    margin-left: 35rem;
    margin-right: 35rem;
    text-align: justify;
}

.best-placeholder-end {
    margin: 10rem;
}

@media (max-width: 1630px) {
    .title-best{
        font-size: 2.5rem;
    }

    .best-mate-title {
        font-size: 1.5rem;
    }

    .best-mate-text {
        margin-left: 30rem;
        margin-right: 30rem;
        font-size: 0.8rem;
    }

    .best-texts {
        font-size: 0.8rem;
    }

    .best-column {
        height: 9rem;
        margin-left: 1.7rem;
        margin-right: 1.7rem;
    }
}

@media (max-width: 1400px) {
    .title-best{
        font-size: 2.5rem;
    }

    .best-mate-title {
        font-size: 1.5rem;
    }

    .best-mate-text {
        margin-left: 25rem;
        margin-right: 25rem;
        font-size: 0.8rem;
    }

    .best-texts {
        font-size: 0.8rem;
    }

    .best-column {
        height: 8rem;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }
}

@media (max-width: 1250px) {
    .title-best{
        font-size: 2.3rem;
    }

    .best-mate-title {
        font-size: 1.3rem;
    }

    .best-mate-text {
        margin-left: 25rem;
        margin-right: 25rem;
        font-size: 0.7rem;
    }

    .best-texts {
        font-size: 0.7rem;
    }

    .best-column {
        height: 8rem;
        margin-left: 1.3rem;
        margin-right: 1.3rem;
    }
}

@media (max-width: 1100px) {
    .title-best{
        font-size: 2.1rem;
    }

    .best-mate-title {
        font-size: 1.1rem;
    }

    .best-mate-text {
        margin-left: 22rem;
        margin-right: 22rem;
        font-size: 0.6rem;
    }

    .best-texts {
        font-size: 0.6rem;
    }

    .best-column {
        height: 8rem;
        margin-left: 1.1rem;
        margin-right: 1.1rem;
    }
}

@media (max-width: 900px) {
    .title-best{
        font-size: 2.0rem;
    }

    .best-mate-title {
        font-size: 1.0rem;
    }

    .best-mate-text {
        margin-left: 18rem;
        margin-right: 18rem;
        font-size: 0.6rem;
    }

    .best-texts {
        font-size: 0.6rem;
    }

    .best-column {
        height: 7rem;
        margin-left: 0.9rem;
        margin-right: 0.9rem;
    }
}

@media (max-width: 750px) {
    .title-best{
        font-size: 1.8rem;
    }

    .best-mate-title {
        font-size: 0.8rem;
    }

    .best-mate-text {
        margin-left: 12rem;
        margin-right: 12rem;
        font-size: 0.5rem;
    }

    .best-texts {
        font-size: 0.5rem;
    }

    .best-column {
        height: 6rem;
        margin-left: 0.7rem;
        margin-right: 0.7rem;
    }
}

@media (max-width: 600px) {
    .title-best{
        font-size: 1.8rem;
    }

    .best-placeholder{
        margin: 10rem;
    }

    .best-mate-title {
        font-size: 0.8rem;
    }

    .best-mate-text {
        margin-left: 10rem;
        margin-right: 10rem;
        font-size: 0.5rem;
    }

    .best-texts {
        font-size: 0.5rem;
    }

    .best-column {
        height: 4rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
}

@media (max-width: 500px) {
    .title-best{
        font-size: 1.5rem;
    }

    .best-placeholder{
        margin: 8rem;
    }

    .best-mate-title {
        font-size: 0.6rem;
    }

    .best-mate-text {
        margin-left: 7rem;
        margin-right: 7rem;
        font-size: 0.5rem;
    }

    .best-texts {
        font-size: 0.5rem;
    }

    .best-column {
        height: 3rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
}

@media (max-width: 430px) {
    .title-best{
        font-size: 1.5rem;
    }

    .best-placeholder{
        margin: 8rem;
    }

    .best-mate-title {
        font-size: 0.6rem;
    }

    .best-mate-text {
        margin-left: 5rem;
        margin-right: 5rem;
        font-size: 0.4rem;
    }

    .best-texts {
        font-size: 0.4rem;
    }

    .best-column {
        height: 2rem;
        margin-left: 0.3rem;
        margin-right: 0.3rem;
    }
}

@media (max-width: 300px) {
    .title-best{
        font-size: 1.2rem;
    }

    .best-placeholder{
        margin: 8rem;
    }

    .best-mate-title {
        font-size: 0.5rem;
    }

    .best-mate-text {
        margin-left: 3rem;
        margin-right: 3rem;
        font-size: 0.3rem;
    }

    .best-texts {
        font-size: 0.3rem;
    }

    .best-column {
        height: 2rem;
        margin-left: 0.2rem;
        margin-right: 0.2rem;
    }
}

@media (max-width: 180px) {
    .title-best{
        font-size: 1rem;
    }

    .best-placeholder{
        margin: 5rem;
    }

    .best-mate-title {
        font-size: 0.3rem;
    }

    .best-mate-text {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        font-size: 0.2rem;
    }

    .best-texts {
        font-size: 0.2rem;
    }

    .best-column {
        height: 2rem;
        margin-left: 0.2rem;
        margin-right: 0.2rem;
    }
}