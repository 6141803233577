.content{
    display: block;
    margin-top: 2rem;
    margin-left: 15rem;
    margin-right: 15rem;
    margin-bottom: 10rem;
    text-align: center;
}

.proposal-image-home{
    display: block;
    width: 50%;
    height: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    border-radius: 30px;
}

.welcome{
    font-size: 2rem;
    font-weight: 400;
    margin-top: 8rem;
    text-align: center;

}

@media (max-width: 1000px) {
    .welcome{
        font-size: 1.8rem;
    }

    .proposal-image-home{
        margin-right: auto;
        margin-left: auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .content{
        margin-right: 15rem;
        margin-left: 15rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 0.7rem;
    }
}

@media (max-width: 850px) {
    .welcome{
        font-size: 1.8rem;
    }

    .proposal-image-home{
        margin-right: auto;
        margin-left: auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 60%;
    }

    .content{
        margin-right: 13rem;
        margin-left: 13rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 0.7rem;
    }
}

@media (max-width: 750px) {
    .welcome{
        font-size: 1.8rem;
    }

    .proposal-image-home{
        margin-right: auto;
        margin-left: auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 65%;
    }

    .content{
        margin-right: 11rem;
        margin-left: 11rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 0.7rem;
    }
}

@media (max-width: 650px) {
    .welcome{
        font-size: 1.6rem;
    }

    .proposal-image-home{
        margin-right: auto;
        margin-left: auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 70%;
    }

    .content{
        margin-right: 9rem;
        margin-left: 9rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 0.7rem;
    }
}

@media (max-width: 550px) {
    .welcome{
        font-size: 1.4rem;
    }

    .proposal-image-home{
        margin-right: auto;
        margin-left: auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 75%;
    }

    .content{
        margin-right: 7rem;
        margin-left: 7rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 0.7rem;
    }
}

@media (max-width: 470px) {
    .welcome{
        font-size: 1.2rem;
    }

    .proposal-image-home{
        margin-right: auto;
        margin-left: auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 75%;
    }

    .content{
        margin-right: 6rem;
        margin-left: 6rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 0.5rem;
    }
}

@media (max-width: 420px) {
    .welcome{
        font-size: 1rem;
    }

    .proposal-image-home{
        margin-right: auto;
        margin-left: auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 75%;
    }

    .content{
        margin-right: 6rem;
        margin-left: 6rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 0.5rem;
    }
}

@media (max-width: 360px) {
    .welcome{
        font-size: 1rem;
    }

    .proposal-image-home{
        margin-right: auto;
        margin-left: auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 80%;
    }

    .content{
        margin-right: 4rem;
        margin-left: 4rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 0.5rem;
    }
}

@media (max-width: 300px) {
    .welcome{
        font-size: 0.8rem;
    }

    .proposal-image-home{
        margin-right: auto;
        margin-left: auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 90%;
    }

    .content{
        margin-right: 3rem;
        margin-left: 3rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 0.5rem;
    }
}
