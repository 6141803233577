.content-det {
    display: block;
    margin-top: 7rem;
}

.content-det h1 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
    text-align: center;
}

.content-det h2 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    text-align: center;
}

.content-det img {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 400px;
    height: auto;
}