.flag-logo-div {
    height: 50px;
    padding: 0;
    display: flex; /* Aligns with flex properties if needed */
    justify-content: center; /* Center the flag horizontally */
    align-items: center; /* Center the flag vertically */
}


.land-flag {
    height: 40%; /* Make the image fill the height of the container */
    width: auto; /* Maintain the aspect ratio of the image */
}
