.content-story{
    display: block;
    margin-top: 8rem;
    margin-bottom: 2rem;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    text-align: center;
}

.content-story-h1{
    display: block;
    text-align: center;
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 2rem;
    margin-bottom: 3rem;
}

.column {
    float: left;
    width: 45%;
    height: 30rem;
    /* border: 1px solid black; */
}

.column-middle {
    width: 9.7%;
}

.column-right {
    float: right;
}

.row:after {
content: "";
display: table;
clear: both;
}

.vertical-line {
    border-left: 1px solid black;
    height: 33rem;
    position: absolute;
    left: 50%;
}

.text-left{
    text-align: right;
    margin: 0;
    padding: 0;
    font-size: 0.7rem;
}

.text-right{
    text-align: left;
    margin: 0;
    padding: 0;
    font-size: 0.7rem;
}

.text-title{
    font-size: 1rem;
    font-weight: 600;
    margin-top: 1rem;
}

.content-div{
    height: 2rem;
}

@media (max-width: 800px) {
    .content-story-h1{
        font-size: 1.5rem;
        margin-bottom: 2rem;
    }

    .column {
        height: 20rem;
    }

    .content-div{
        height: 1.8rem;
    }

    .text-title{
        font-size: 0.9rem;
    }

    .text-left{
        font-size: 0.7rem;
    }

    .text-right{
        font-size: 0.7rem;
    }

    .vertical-line {
        height: 30rem;
    }
}

@media (max-width: 650px) {
    .content-story-h1{
        font-size: 1.5rem;
        margin-bottom: 2rem;
    }

    .column {
        height: 15rem;
    }

    .content-div{
        height: 1.8rem;
    }

    .text-title{
        font-size: 0.9rem;
    }

    .text-left{
        font-size: 0.7rem;
    }

    .text-right{
        font-size: 0.7rem;
    }

    .vertical-line {
        height: 30rem;
    }
}

@media (max-width: 550px) {
    .content-story-h1{
        font-size: 1.3rem;
        margin-bottom: 1rem;
    }

    .column {
        height: 15rem;
    }

    .content-div{
        height: 1.5rem;
    }

    .text-title{
        font-size: 0.9rem;
    }

    .text-left{
        font-size: 0.7rem;
    }

    .text-right{
        font-size: 0.7rem;
    }

    .vertical-line {
        height: 28rem;
    }
}

@media (max-width: 400px) {
    .content-story-h1{
        font-size: 1rem;
        margin-bottom: 1rem;
    }

    .column {
        height: 10rem;
    }

    .content-div{
        height: 1.3rem;
    }

    .text-title{
        font-size: 0.7rem;
    }

    .text-left{
        font-size: 0.5rem;
    }

    .text-right{
        font-size: 0.5rem;
    }

    .vertical-line {
        height: 25rem;
    }
}

@media (max-width: 321px), (max-height: 560px){
    .content-story-h1{
        font-size: 1rem;
        margin-bottom: 1rem;
    }

    .column {
        height: 10rem;
    }

    .content-div{
        height: 1.3rem;
    }

    .text-title{
        font-size: 0.7rem;
    }

    .text-left{
        font-size: 0.5rem;
    }

    .text-right{
        font-size: 0.5rem;
    }

    .vertical-line {
        height: 25rem;
    }

    .placeholder {
        margin: 30rem;
    }
}
