.footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    text-align: center;
    padding: 0;
    margin: 0;
    background-color: #F7F7F7;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-text, .heart-footer-logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.heart-footer-logo {
    width: 18px;
    height: 18px;
}

.footer-text {
    font-size: 12px;
    line-height: 1;
    margin: 0 5px;
}
